import React from 'react';

function LoadingIcon({ type }: { type: 'ring' | 'dots' }) {
  if (type === 'ring') {
    return (
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default LoadingIcon;
